import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import typographySizes from 'src/assets/styles/typographySizes.js'
import colors from 'src/assets/styles/colors.js'
import mediaQuery from 'src/assets/styles/mediaQuery.js'
import distances from 'src/assets/styles/distances.js'

import hex from 'src/assets/svg/footer-hex.svg'
import Fb from '/src/assets/svg/fb.svg'
import Insta from '/src/assets/svg/insta.svg'

import MainWrapper from 'src/components/global/MainWrapper.js'

import mianInfo from 'src/assets/data/ mianInfo.js'

const Bg = styled.div`
  background-color: ${colors.darkGreen};
  width: 100%;
`

const ContactWrapper = styled.div`
  width: 83.33%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: ${mediaQuery.laptop}) {
    width: 100%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`

const BottomBar = styled.div`
  background-color: ${colors.bright};
  border-radius: 30rem;
  margin-bottom: 30rem;
  justify-content: space-between;
  display: flex;
  padding: 30rem;

  p {
    font-size: ${typographySizes.s}rem;
    color: ${colors.dark};
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-wrap: wrap;
    border-radius: 9rem;
  }
`

const BottomBarInfo = styled.div`
  width: calc(50% - 20rem);
  margin-right: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`
const SmallFooter = styled.p`
  color: ${colors.dark};
  font-size: 10rem !important;
  margin-top: 10rem;
  a {
    text-decoration: none;
    color: ${colors.dark};
    font-size: 10rem;
  }
`

const OpenWrapper = styled.div`
  width: calc(25% - 20rem);
  margin-right: 20rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 20rem;
  }
`

const SocialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(25% - 20rem);
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-top: 20rem;
  }
`

const StyledSocialLink = styled(Link)`
  font-size: ${typographySizes.s}rem;
  padding: 0rem 7.5rem;
  align-items: center;
  display: flex;

  @media (max-width: ${mediaQuery.tablet}) {
    padding: 10rem 15rem;
    width: 40rem;
    img {
      width: 100%;
    }
  }
`

// ContactBox

const ContactBoxWrapper = styled.div`
  position: relative;
  margin: 60rem 0;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 64rem;
    height: 74rem;

    background-image: url(${hex});
    background-size: contain;
    background-position: center;
    z-index: 0;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin: 30rem 0;
    margin-left: 32rem;
  }
`

const ContactBoxTitle = styled.h4`
  font-size: ${typographySizes.s}rem;
  font-family: 'Poppins', sans-serif;
  color: ${colors.white};
  line-height: 1;
  margin-bottom: 5rem;
  position: relative;

  z-index: 2;
`

const ContactBoxInfo = styled.a`
  text-decoration: none;
  font-size: 25rem;
  color: ${colors.white};
  line-height: 1;
  position: relative;
  z-index: 2;
  @media (max-width: ${mediaQuery.laptop}) {
    font-size: ${typographySizes.sm}rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 25rem;
  }
`

const ContactBox = props => (
  <ContactBoxWrapper
    data-sal="slide-up"
    data-sal-easing="ease"
    data-sal-duration="500"
  >
    <ContactBoxTitle>{props.title}</ContactBoxTitle>

    {props.tel && (
      <ContactBoxInfo href={`tel:${props.link}`}>{props.info}</ContactBoxInfo>
    )}
    {props.mail && (
      <ContactBoxInfo href={`mailto:${props.link}`}>
        {props.info}
      </ContactBoxInfo>
    )}
    {props.map && (
      <ContactBoxInfo href={props.link} target="_blank">
        {props.info}
      </ContactBoxInfo>
    )}
  </ContactBoxWrapper>
)

const Footer = props => {
  return (
    <Bg>
      <MainWrapper>
        <ContactWrapper id="kontakt">
          {/* <ContactBox
            title="zadzwoń i zamów"
            info={mianInfo.tel}
            link={mianInfo.tel}
            tel
          /> */}
          {/* <ContactBox
            title="odwiedź nas"
            info="Browarna 6"
            link="https://goo.gl/maps/gJbaThUztZFAEqjUA"
            map
          /> */}
          <ContactBox
            title="napisz do nas"
            info={mianInfo.mail}
            link={mianInfo.mail}
            mail
          />
        </ContactWrapper>
        <BottomBar
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="500"
          data-sal-delay="200"
        >
          <BottomBarInfo>
            <p>©2021 - KIMIDORI | All right reserved</p>
            {/* <SmallFooter>
              projekt i wdrożenie:{' '}
              <a href="https://we3studio.pl/" target="_blank">
                we3studio.pl
              </a>
            </SmallFooter> */}
          </BottomBarInfo>
          {/* <OpenWrapper>
            {mianInfo.open.map(day => (
              <>
                <p>
                  {day.day}: {day.hours}{' '}
                </p>
              </>
            ))}
          </OpenWrapper> */}
          <SocialWrapper>
            <StyledSocialLink to={mianInfo.facebook} target="_blank">
              <img src={Fb} />
            </StyledSocialLink>
            <StyledSocialLink to={mianInfo.instagram} target="_blank">
              <img src={Insta} />
            </StyledSocialLink>
          </SocialWrapper>
        </BottomBar>
      </MainWrapper>
    </Bg>
  )
}

export default Footer
